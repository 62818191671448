import { createRouter, createWebHistory } from 'vue-router';
import { _LAYOUT_NAME, _ROUTER_NAME } from '@/enums';
import { _var } from '@/enums/Common';
import { $api } from '@/services';
import store from '@/store';

const Routes: any = [
	{
		path: '/',
		name: _ROUTER_NAME.HOME,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/home/Home.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
		},
	},
	{
		path: '/signin',
		name: _ROUTER_NAME.LOGIN,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/login/Login.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
		},
	},
	{
		path: '/account_invitations',
		name: _ROUTER_NAME.REGISTER,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/register/Register.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
			guest: true,
		},
	},
	{
		path: '/staff_invitations',
		name: _ROUTER_NAME.STAFF_REGISTER,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/staff/register/StaffRegister.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
			guest: true,
		},
	},
	{
		path: '/invalid-token',
		name: _ROUTER_NAME.INVALID_TOKEN,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/invalidToken/InvalidToken.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
		},
	},
	{
		path: '/forgot-password',
		name: _ROUTER_NAME.FORGOT_PASSWORD,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/forgotPassword/ForgotPassword.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
		},
	},
	{
		path: '/reset_password',
		name: _ROUTER_NAME.RESET_PASSWORD,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/updatePassword/UpdatePassword.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.AUTH_LAYOUT,
			guest: true,
		},
	},
	{
		path: '/setup-center',
		name: _ROUTER_NAME.SETUP_CENTER,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/setupCenter/SetupCenter.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
		children: [
			{
				path: '/setup-center',
				name: _ROUTER_NAME.SETUP_INFORMATION,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/information/Information.vue'
					),
			},
			{
				path: '/setup-center/timesheet',
				name: _ROUTER_NAME.SETUP_TIMESHEET,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/timesheet/Timesheet.vue'
					),
			},
			{
				path: '/setup-center/boarding',
				name: _ROUTER_NAME.DAYCARE_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/boarding/Boarding.vue'
					),
			},
			/*{
	path: '/setup-center/room',
	name: _ROUTER_NAME.SETUP_ROOM,
	component: () =>
	  import(
	  /!* webpackChunkName: "__components" *!/ '@/views/setupCenter/features/room/Room.vue'
	  ),
	},*/
			{
				path: '/setup-center/schedule',
				name: _ROUTER_NAME.SETUP_SCHEDULE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/schedule/Schedule.vue'
					),
			},
			{
				path: '/setup-center/boarding',
				name: _ROUTER_NAME.SETUP_BOARDING,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/boarding/Boarding.vue'
					),
			},
			{
				path: '/setup-center/target-smart',
				name: _ROUTER_NAME.SETUP_TARGET_SMART,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/targetSmart/TargetSmart.vue'
					),
			},
			{
				path: '/setup-center/goals',
				name: _ROUTER_NAME.SETUP_TARGET_GOALS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupGoals/SetupGoals.vue'
					),
			},
			{
				path: '/setup-center/assistance-level',
				name: _ROUTER_NAME.SETUP_ASSISTANCE_LEVEL,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/assistanceLevel/AssistanceLevel.vue'
					),
			},
			{
				path: '/setup-center/report',
				name: _ROUTER_NAME.SETUP_REPORT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/report/Report.vue'
					),
			},
			{
				path: '/setup-center/periodic-assessment',
				name: _ROUTER_NAME.SETUP_PERIODIC_ASSESSMENT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/periodicAssessment/PeriodicAssessment.vue'
					),
			},
			{
				path: '/setup-center/others',
				name: _ROUTER_NAME.SETUP_OTHERS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/setupCenter/features/others/Others.vue'
					),
			},
		],
	},
	{
		path: '/daycare-class',
		name: _ROUTER_NAME.DAYCARE_CLASS,
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/daycareClass/DaycareClass.vue'
			),
	},
	{
		path: '/daycare-class/:id',
		name: _ROUTER_NAME.DAYCARE_CLASS_DETAIL,
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/daycareClassDetail/DaycareClassDetail.vue'
			),
	},
	{
		path: '/record',
		name: _ROUTER_NAME.RECORD,
		redirect: {
			name: _ROUTER_NAME.RECORD_EMPLOYEE,
		},
	},
	{
		path: '/record/employee',
		name: _ROUTER_NAME.RECORD_EMPLOYEE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/record/employee/Employee.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/record/employee/:id',
		name: _ROUTER_NAME.RECORD_EMPLOYEE_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/record/employeeDetail/EmployeeDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/record/student',
		name: _ROUTER_NAME.RECORD_STUDENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/record/students/Students.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: [
				'leader',
				'support',
				'admin',
				'org_admin',
				'accountant',
				'teacher',
			],
		},
	},
	{
		path: '/record/student/:id',
		name: _ROUTER_NAME.RECORD_STUDENT_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/record/studentDetail/StudentDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: [
				'leader',
				'support',
				'admin',
				'org_admin',
				'accountant',
				'teacher',
			],
		},
		children: [
			{
				path: '/record/student/:id/info',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_INFO,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/info/Info.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/result',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_RESULT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/result/Result.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/result/:result_id',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_RESULT_DETAIL,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/result/ResultDetail.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/plan',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_PLAN,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/plan/Plan.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id',
				name: _ROUTER_NAME.RECORD_STUDENT_STATS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/stats/Stats.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/staff-list',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_STAFF,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/staffList/StaffList.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'accountant',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/target',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/reportTarget/ReportTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/completed-target',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/completedTarget/CompletedTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/record/student/:id/stopped-target',
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET_STOP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/stopTarget/StopTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/record/student/:id/target/create',
		name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET_CREATE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/record/studentDetail/features/reportTarget/createGoals/CreateGoals.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/goal-management',
		name: _ROUTER_NAME.GOAL_MANAGEMENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalManagement/GoalManagement.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/goal-management/:id',
		name: _ROUTER_NAME.GOAL_MANAGEMENT_STUDENT_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalManagement/features/studentDetail/StudentDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/goal-management/:id',
				name: _ROUTER_NAME.GOAL_MANAGEMENT_STUDENT_SUMMARY,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/goalManagement/features/summaryView/SummaryView.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/goal-management/:id/skill-details',
				name: _ROUTER_NAME.GOAL_MANAGEMENT_STUDENT_SKILL_DETAILS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/goalManagement/features/skillDetails/SkillDetails.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/goal-management/:id/skill-details/:goal',
				name: _ROUTER_NAME.GOAL_MANAGEMENT_STUDENT_GOAL_DETAILS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/goalManagement/features/goalDetails/GoalDetails.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/report/day-care',
		name: _ROUTER_NAME.REPORT_DAY_CARE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/dayCareClasses/dayCareClasses.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/lesson',
		name: _ROUTER_NAME.REPORT_LESSON,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/lesson/Lesson.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/week',
		name: _ROUTER_NAME.REPORT_WEEK,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/week/Week.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/report/week',
				name: _ROUTER_NAME.REPORT_WEEK_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/report/features/week/WeekClass.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/report/week/group-class',
				name: _ROUTER_NAME.REPORT_WEEK_GROUP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/report/features/week/WeekGroup.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/report/week/:id',
		name: _ROUTER_NAME.REPORT_WEEK_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/weekDetail/WeekDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/lesson/:id',
		name: _ROUTER_NAME.REPORT_STUDENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/student/Student.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/day-care/:id',
		name: _ROUTER_NAME.REPORT_DAY_CARE_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/dayCareDetail/DayCareDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/monthly-report',
		name: _ROUTER_NAME.REPORT_PLANS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/plans/Plans.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/report/monthly-report',
				name: _ROUTER_NAME.REPORT_PLANS_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/report/features/plans/PlansClass.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/report/monthly-report/group-class',
				name: _ROUTER_NAME.REPORT_PLANS_GROUP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/report/features/plans/PlansGroup.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/report/week/group-class/:id/:report_id',
		name: _ROUTER_NAME.REPORT_WEEK_GROUP_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/weekReportGroupDetail/WeekReportGroupDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/week/group-class/:id/edit',
		name: _ROUTER_NAME.REPORT_WEEK_GROUP_EDIT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/weekGroupEdit/WeekGroupEdit.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/monthly-report/group-class/:id/detail',
		name: _ROUTER_NAME.REPORT_PLANS_GROUP_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/planGroupDetail/PlanGroupDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/monthly-report/group-class/:id/edit',
		name: _ROUTER_NAME.REPORT_PLANS_GROUP_EDIT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/planGroupEdit/PlanGroupEdit.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/monthly-report/:id',
		name: _ROUTER_NAME.REPORT_MONTHLY,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/monthlyReport/MonthlyReport.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/monthly-report/:id/:goalId',
		name: _ROUTER_NAME.REPORT_MONTHLY_GOAL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/report/features/goalDetail/GoalDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/group',
		name: _ROUTER_NAME.REPORT_GROUP,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassReport/GroupClassReport.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/report/group/:id/:reportId',
		name: _ROUTER_NAME.REPORT_GROUP_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassReportDetail/GroupClassReportDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/goal/:id/:goal_id',
		name: _ROUTER_NAME.GOAL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goal/Goal.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-class/:id/:goal_id',
		name: _ROUTER_NAME.GOAL_IN_GROUP,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalInGroup/GoalInGroup.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/plans',
		name: _ROUTER_NAME.PLANS_REPORT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/plansReport/PlansReport.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/plans/:id',
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/plansDetail/PlansDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/plans/:id',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__CURRENT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/reportTarget/ReportTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/all',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__ALL,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/all/AllPlan.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/future',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/reportTargetFuture/ReportTargetFuture.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/completed',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/completedTarget/CompletedTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/history-plan',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__HISTORY_PLAN,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/historyPlan/HistoryPlan.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/goal-bank',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_BANK,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/goalBank/GoalBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/stopped-target',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_STOP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/plansDetail/features/stopTarget/StopTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/plans/:id/target-future',
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/plansDetail/features/reportTargetFuture/createGoals/CreateGoals.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			menu: 'plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/plans/:id/target-future/:plan_id/create',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTargetFuture/CreateGoalFuture.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target-future/:plan_id/create-by-bank',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BY_BANK,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTargetFuture/GoalByBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target-future/:plan_id/create-by-stop',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_STOP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTargetFuture/GoalByStop.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target-future/:plan_id/create-by-completed',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTargetFuture/GoalByCompleted.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/plans/:id/target-current',
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/plansDetail/features/reportTarget/createGoals/CreateGoals.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			menu: 'plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/plans/:id/target/:plan_id/create',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTarget/CreateGoalsCurrent.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target/:plan_id/create-by-bank',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_BANK,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTarget/GoalByBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target/:plan_id/create-by-stop',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BAY_STOP,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTarget/GoalByStop.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/target/:plan_id/create-by-completed',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTarget/GoalByCompleted.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/plans/:id/goal-bank',
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_LIB,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/plansDetail/features/goalBank/createGoals/CreateGoals.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			menu: 'plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/plans/:id/goal-bank/create',
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_LIB__CREATE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/components/modules/reportTargetLib/CreateGoalsLib.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/goal-bank/create-by-lib',
				name: _ROUTER_NAME.CREATE_GOAL_BY_LIB,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLib/CreateGoalByLib.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/plans/:id/goal-bank/create-by-lib-goals/:goalId',
				name: _ROUTER_NAME.CREATE_GOAL_BY_LIB_GOALS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLib/CreateGoalByLib_Goals.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/plan-in-history/:student_id/:id',
		name: _ROUTER_NAME.PLAN_IN_HISTORY,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/planInHistory/PlanInHistory.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/notifications',
		name: _ROUTER_NAME.NOTIFICATION,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/notifications/Notifications.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/notifications/create',
		name: _ROUTER_NAME.NOTIFICATION_CREATE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/notificationCreate/NotificationCreate.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/notifications/:id',
		name: _ROUTER_NAME.NOTIFICATION_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/notificationDetail/NotificationDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/fee-notices',
		name: _ROUTER_NAME.FEE_NOTICES,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/feeNotifications/FeeNotices.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/fee-notices/create',
		name: _ROUTER_NAME.FEE_NOTICES_CREATE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/feeNoticesCreate/FeeNoticesCreate.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/fee-notices/:id/edit',
		name: _ROUTER_NAME.FEE_NOTICES_EDIT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/feeNoticesCreate/FeeNoticesCreate.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/fee-notices/:id',
		name: _ROUTER_NAME.FEE_NOTICES_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/feeNotoceDetail/FeeNoticeDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/fee-notices/:id/preview',
		name: _ROUTER_NAME.FEE_NOTICES_PREVIEW,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/feeNotifyPreview/FeeNoticeDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/fee-notices/mark_paid',
		name: _ROUTER_NAME.FEE_NOTICES_MARK_PAID,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/markPaid/MarkPaid.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'fee_notices',
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/schedule/timetable',
		name: _ROUTER_NAME.SCHEDULE_TIMETABLE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/timetable/TimeTable.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/schedule/timetable',
				name: _ROUTER_NAME.SCHEDULE_TIMETABLE_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/timetable/TimeTableClass.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/schedule/timetable/group-classes',
				name: _ROUTER_NAME.SCHEDULE_TIMETABLE_GROUP_CLASSES,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/timetable/TimeTableGroupClass.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/schedule/timetable-history',
		name: _ROUTER_NAME.SCHEDULE_TIMETABLE_HISTORY,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/timetableHistory/TimeTableHistory.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/schedule/stats',
		name: _ROUTER_NAME.SCHEDULE_STATS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/scheduleStats/ScheduleStats.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/schedule/stats/student',
		name: _ROUTER_NAME.SCHEDULE_STATS_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/scheduleStats/ScheduleStatsDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/schedule/stats/student',
				name: _ROUTER_NAME.SCHEDULE_STATS_DETAIL__CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/scheduleStats/features/ScheduleStatsDetail__class.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/schedule/stats/student/daycare',
				name: _ROUTER_NAME.SCHEDULE_STATS_DETAIL__DAYCARE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/scheduleStats/features/ScheduleStatsDetail__daycare.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/schedule/stats/student/group-class',
				name: _ROUTER_NAME.SCHEDULE_STATS_DETAIL__GROUP_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/scheduleStats/features/ScheduleStatsDetail__group.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/packages',
		name: _ROUTER_NAME.PACKAGE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/packages/Packages.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'accountant'],
		},
	},
	{
		path: '/goal-lib',
		name: _ROUTER_NAME.GOAL_LIB,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalLib/GoalLib.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'admin'],
			menu: 'goal_lib',
		},
	},
	{
		path: '/goal-folders/:id',
		name: _ROUTER_NAME.GOAL_FOLDERS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalFolders/GoalFolders.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'admin'],
			menu: 'goal_lib',
		},
	},
	{
		path: '/goal-reference',
		name: _ROUTER_NAME.GOAL_REFERENCE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalReference/GoalReference.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'admin'],
			menu: _ROUTER_NAME.GOAL_REFERENCE,
		},
	},
	{
		path: '/goal-reference-folders/:id',
		name: _ROUTER_NAME.GOAL_REFERENCE_FOLDERS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/goalReferenceFolders/GoalReferenceFolders.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'admin'],
			menu: 'goal_lib',
		},
	},
	{
		path: '/dashboard',
		name: _ROUTER_NAME.DASHBOARD,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/dashboardOrg/DashboarOrg.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'org_admin', 'admin'],
		},
	},
	{
		path: '/dashboard-org',
		name: _ROUTER_NAME.DASHBOARD_ORG,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/dashboard/Dashboard.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support'],
		},
	},
	{
		path: '/holiday',
		name: _ROUTER_NAME.HOLIDAY,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/holidays/Holidays.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/media',
		name: _ROUTER_NAME.SETUP_MEDIA,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/media/Media.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'media',
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/media-history',
		name: _ROUTER_NAME.MEDIA_HISTORY,
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'media',
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/media/MediaHistory.vue'
			),
	},
	{
		path: '/birthdays',
		name: _ROUTER_NAME.BIRTHDAY,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/birthdays/Birthdays.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/add-students/:id',
		name: _ROUTER_NAME.ADD_STUDENTS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/addStudents/AddStudents.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/add-students-periodic',
		name: _ROUTER_NAME.SETUP_PERIODIC_ASSESSMENT_ADD_STUDENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/addStudentsPeriodicAssessment/AddStudents.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/timetable-student/:id',
		name: _ROUTER_NAME.TIMETABLE_STUDENTS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/timeTableStudent/TimeTableStudent.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/schedule/timeslot',
		name: _ROUTER_NAME.SCHEDULE_TIMESLOT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/timeSlot/TimeSlot.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/schedule/timeslot',
				name: _ROUTER_NAME.SCHEDULE_TIMESLOT__CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/timeSlot/features/TimeSlotClass.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/schedule/timeslot/daycare',
				name: _ROUTER_NAME.SCHEDULE_TIMESLOT__DAYCARE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/timeSlot/features/TimeSlotDaycare.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/schedule/timeslot/group',
				name: _ROUTER_NAME.SCHEDULE_TIMESLOT__GROUP_CLASSES,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/timeSlot/features/TimeSlotGroupClass.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/session-management',
		name: _ROUTER_NAME.SESSION_MANAGEMENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/sessionManagement/SessionManagement.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/session-management',
				name: _ROUTER_NAME.SESSION_MANAGEMENT__CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/sessionManagement/features/SessionManagementClass.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/session-management/daycare',
				name: _ROUTER_NAME.SESSION_MANAGEMENT__DAYCARE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/sessionManagement/features/SessionManagementDaycare.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/session-management/group-class',
				name: _ROUTER_NAME.SESSION_MANAGEMENT__GROUP_CLASS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/sessionManagement/features/SessionManagementGroupClass.vue'
					),
				meta: {
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/transferred-data',
		name: _ROUTER_NAME.TRANSFERRED_DATA,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/transferredData/TransferredData.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin'],
		},
	},
	{
		path: '/student/:id/old-data',
		name: _ROUTER_NAME.OLD_STUDENT_DATA,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/oldStudentData/OldStudentData.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/student/:id/old-data',
				name: _ROUTER_NAME.OLD_STUDENT_DATA_LESSON,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/oldStudentData/features/Lesson.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/student/:id/old-data/daycare',
				name: _ROUTER_NAME.OLD_STUDENT_DATA_DAYCARE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/oldStudentData/features/dayCareClasses.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/student/:id/old-data/weekly',
				name: _ROUTER_NAME.OLD_STUDENT_DATA_WEEK,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/oldStudentData/features/Week.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/student/:id/old-data/plan',
				name: _ROUTER_NAME.OLD_STUDENT_DATA_PLAN,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/oldStudentData/features/Plans.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/profile',
		name: _ROUTER_NAME.PROFILE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/profile/Profile.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: [
				'leader',
				'support',
				'admin',
				'org_admin',
				'accountant',
				'teacher',
			],
		},
	},
	{
		path: '/create-group/:id',
		name: _ROUTER_NAME.CREATE_GROUP,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/createGroup/CreateGroup.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/create-group-class/:id',
		name: _ROUTER_NAME.CREATE_GROUP_CLASS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/createGroupClass/CreateGroupClass.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-classes',
		name: _ROUTER_NAME.GROUP_CLASSES,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClasses/GroupClasses.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-classes/:id',
		name: _ROUTER_NAME.GROUP_CLASSES_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassDetail/GroupClassDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-classes/:id/add-student',
		name: _ROUTER_NAME.GROUP_CLASSES_ADD_STUDENT,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassAddStudents/GroupClassAddStudents.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-classes/:id/add-staff',
		name: _ROUTER_NAME.GROUP_CLASSES_ADD_STAFF,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassAddStaffs/GroupClassAddStaffs.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-class-plans',
		name: _ROUTER_NAME.GROUP_CLASS_PLANS,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassPlans/GroupClassPlans.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-class-plans/:id',
		name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/GroupClassPlansDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			menu: 'group_class_plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/group-class-plans/:id',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/reportTarget/ReportTarget.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/future',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/reportFuture/ReportFuture.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/history',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_HISTORY,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/reportInHistory/ReportInHistory.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/goal-bank',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_BANK,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/goalBank/GoalBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/goals-completed',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/goalsCompleted/GoalsCompleted.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/goals-stopped',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_STOPPED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/goalsStopped/GoalsStopped.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/goals-all',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_ALL,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlansDetail/features/goalsAll/GoalsAll.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					menu: 'group_class_plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/group-class-plans/:id/plans/:planId',
		name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_DETAIL,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassPlanDetail/GroupClassPlanDetail.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			menu: 'group_class_plans',
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/group-class-plans/:id/create',
		name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoal/GroupClassPlanCreateGoal.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/group-class-plans/:id/create',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoal/features/NewGoal.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-by-stopped',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_STOPPED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoal/features/GoalByStopped.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-by-completed',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_COMPLETED,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoal/features/GoalByCompleted.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-by-bank',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_LIB,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoal/features/GoalByBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-by-lib',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_TVMT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLibForGroup/CreateGoalByLib.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-by-lib-goals/:goalId',
				name: _ROUTER_NAME.CREATE_GOAL_BY_LIB_GOALS_TVMT,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLibForGroup/CreateGoalByLib_Goals.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/group-class-plans/:id/create-future',
		name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_FUTURE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoalFuture/GroupClassPlanCreateGoal.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/group-class-plans/:id/create-future',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoalFuture/features/NewGoal.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-future-by-bank',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_LIB_FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/groupClassPlanCreateGoalFuture/features/GoalByBank.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-future-by-lib',
				name: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_CREATE_BY_NEW_GOAL_FROM_TVMT_FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLibForGroup/CreateGoalByLib.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/group-class-plans/:id/create-future-by-lib-goals/:goalId',
				name: _ROUTER_NAME.CREATE_GOAL_BY_LIB_GOALS_TVMT_FUTURE,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/createGoalByLibForGroup/CreateGoalByLib_Goals.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.NONE_LAYOUT,
					login: true,
					menu: 'plans',
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/documents',
		name: _ROUTER_NAME.DOCUMENTS_WRAPPER,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/documents/DocumentWrapper.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/documents',
				name: _ROUTER_NAME.DOCUMENTS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/documents/Documents.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/luca-documents',
				name: _ROUTER_NAME.LUCA_DOCUMENTS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/documents/LucaDocuments.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/record/student/:id/plan/preview',
		name: _ROUTER_NAME.PLAN_PREVIEW,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/planPreview/PlanPreview.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/tuition/setting',
		name: _ROUTER_NAME.TUITION,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/tuition/Tuition.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
		children: [
			{
				path: '/tuition/setting',
				name: _ROUTER_NAME.TUITION_SETTING,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/tuitionSetting/TuitionSetting.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
			{
				path: '/tuition/forms',
				name: _ROUTER_NAME.TUITION_FORMS,
				component: () =>
					import(
						/* webpackChunkName: "__components" */ '@/views/forms/Forms.vue'
					),
				meta: {
					layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
					login: true,
					roles: [
						'leader',
						'support',
						'admin',
						'org_admin',
						'teacher',
					],
				},
			},
		],
	},
	{
		path: '/tuition/forms/create',
		name: _ROUTER_NAME.TUITION_FORMS_CREATE,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/formCreate/FormCreate.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/tuition/forms/preview',
		name: _ROUTER_NAME.TUITION_FORMS_PREVIEW,
		component: () =>
			import(
				/* webpackChunkName: "__components" */ '@/views/formPreview/FormPreview.vue'
			),
		meta: {
			layout: _LAYOUT_NAME.NONE_LAYOUT,
			login: true,
			roles: ['leader', 'support', 'admin', 'org_admin', 'teacher'],
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: _ROUTER_NAME.NOT_FOUND,
		meta: {
			layout: _LAYOUT_NAME.DEFAULT_LAYOUT,
			login: true,
		},
		component: () => import('@/views/notFound/NotFound.vue'),
	},
];

const router = createRouter({
	history: createWebHistory('/'),
	routes: Routes,
});

router.beforeEach(async (to, from, next) => {
	const token = localStorage.getItem(_var.TOKEN);
	const subDomain = localStorage.getItem(_var.SUBDOMAIN);

	if (to.meta?.login) {
		const res = await $api.auth.profile();
		if (res && res?.data && res?.data?.data) {
			await store.dispatch('setProfile', res.data.data);
			if (!res.data.data.is_active_org) {
				const message = store.getters.isManager
					? 'Chúng tôi rất tiếc phải khóa tài khoản của quý cơ sở của trên Luca Education, do gói đăng ký của quý cơ sở hiện đã hết hạn hoặc chưa hoàn thành thanh toán. Vui lòng gia hạn gói hoặc hoàn thành thanh toán để tiếp tục sử dụng.'
					: 'Không thể thực hiện báo cáo do cơ sở đã bị khóa';
				await store.dispatch('setAlertMessageDontHide', {
					message: message,
					type: 'error',
				});
				// }
			} else if (
				res.data.data.new_students_transfered &&
				res.data.data.new_students_transfered.length
			) {
				let message;
				if (res.data.data.new_students_transfered.length === 1) {
					message = `<span style="font-weight: 600">Hồ sơ của bé ${res.data.data.new_students_transfered[0].name} vừa được chuyển sang, hiện bé chưa có trị liệu viên phụ trách tại cơ sở, vui lòng bổ nhiệm trị liệu viên phù hợp.</span> <a href="/transferred-data?hide_toast=true" class="underline cursor-pointer">Xem chi tiết.</a>`;
				} else {
					message = `<span style="font-weight: 600"> Có ${res.data.data.new_students_transfered.length} hồ sơ vừa được chuyển sang, hiện các bé chưa có trị liệu viên phụ trách tại cơ sở, vui lòng bổ nhiệm trị liệu viên phù hợp.</span> <a href="/transferred-data?hide_toast=true" class="underline cursor-pointer">Xem chi tiết</a>`;
				}
				await store.dispatch('setAlertMessageDontHide', {
					message: message,
				});
			} else {
				await store.dispatch('clearAlert');
			}
		}

		const roles: string[] = (to.meta?.roles as string[]) || [];
		const role: any = res?.data?.data?.staff?.role;

		if (roles.length && !roles.includes(role)) {
			// @ts-ignore
			next({ name: from?.name || _ROUTER_NAME.NOT_FOUND });
		}
	}

	// logged after validate token
	const isLogged = !!(token && subDomain);

	// check login required
	if (to.matched.some((record) => record?.meta?.guest)) {
		next();
	} else if (to.matched.some((record) => record?.meta?.login)) {
		if (isLogged) {
			next();
		} else {
			// @ts-ignore
			next({ name: from?.name || _ROUTER_NAME.LOGIN });
		}
	} else {
		if (!isLogged || to.meta?.public) {
			next();
		} else {
			// @ts-ignore
			next({ name: from?.name || _ROUTER_NAME.HOME });
		}
	}
});

export default router;
